import { FC } from "react";

import cn from "classnames";
import { useTranslation } from "react-i18next";

import useTheme from "@hooks/useThemes";
import { getCurrentYear } from "@utils/date";

import styles from "./Footer.module.scss";

interface FooterProps {
  className?: string | undefined;
}

const Footer: FC<FooterProps> = ({ className }) => {
  const { t } = useTranslation();
  const { theme } = useTheme();

  return (
    <footer
      className={cn(className, styles.root, {
        [styles.dark]: theme === "dark",
        [styles.light]: theme === "light"
      })}
      style={{ borderTop: "1px solid black" }}
    >
      <div className={styles.text}>{t("footer-text")}{" "}{getCurrentYear()}</div>
    </footer>
  );
};

export default Footer;
